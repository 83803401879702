<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form label-position="left">
        <el-row :gutter="40">
          <el-col :span="12">
            <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">页面参数</div>
            <el-form-item label="入驻页顶部图片" >
              <y_upload_img v-model="form.settled_page_banner"></y_upload_img>
            </el-form-item>
            <el-form-item label="入驻协议">
              <br>
              <y_editor v-model="form.settled_agreement" eid="agreement"></y_editor>
            </el-form-item>
            <el-form-item label="押金提现说明">
              <br>
              <y_editor v-model="form.settled_deposit_get_desc" eid="desc"></y_editor>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">规则配置</div>
            <el-form-item label="商家入驻方式">
              <el-radio-group v-model="form.settled_func">
                <el-radio :label="0">付费入驻</el-radio>
                <el-radio :label="1">免费入驻</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="入驻押金">
              <el-input-number v-model="form.settled_deposit"></el-input-number>元,
              达标退回<el-input-number v-model="form.settled_deposit_return_fee"></el-input-number>元至商家老板个人佣金账户
            </el-form-item>
            <el-form-item label="退回条件">
              <el-input-number v-model="form.settled_deposit_return_day_limit"></el-input-number>天内
              自身直推+员工直推，推广至少
              <el-input-number v-model="form.settled_deposit_return_order_min"></el-input-number>
              个免单卡订单
            </el-form-item>
            <el-divider content-position="left">商家权益</el-divider>
              <div class="y-desc">商家老板本人及员工可享受 <strong>免单卡直推奖励权益</strong></div>
              <div class="y-desc">不与其它身份的免单卡直推奖励权益叠加</div>
            <el-divider content-position="left">商家老板专享权益</el-divider>
            <el-form-item label="权益说明">
              <div class="y-desc">商家老板本人直推免单卡订单得此权益金额</div>
              <div class="y-desc">商家员工邀请关系链下新免单卡订单 商家老板获得此权益金额</div>
            </el-form-item>
            <el-form-item label="奖励金额">
              <el-input-number v-model="form.rights_boss_award"></el-input-number>元
            </el-form-item>
            <el-form-item label="关闭奖励">
              <el-switch v-model="form.award_close"></el-switch>
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import y_editor from "@/components/y-form/y-editor/y_editor";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "edit",
  components: {
    y_editor,
    y_upload_img,
  },
  data() {
    return {
      form: {
        settled_page_banner:"",
        settled_agreement:"",
        settled_deposit:0,
        settled_deposit_return_day_limit:0,
        settled_deposit_return_order_min:0,
        settled_deposit_return_fee:0,
        rights_boss_award:0,
        settled_deposit_get_desc:"",
        settled_func:0,
        award_close:false,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.plugin.privilegeCard.ruleMerchant().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.shop.plugin.privilegeCard.ruleMerchantEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>